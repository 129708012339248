import Header from "../common/Header";
import Text from "../common/Text";

const FormalSection = ({formal}) => {
    return (
        <div className="text-left pb-7">
            {formal.header && <Header className={'mb-2 md:mb-[20px] font-size-display-sm line-height-display-sm font-semibold'}>
                {formal.header}
            </Header>}
            {
                formal.subHeader && 
                <Header variant='h2' className={'mb-2 md:mb-[20px] font-size-text-lg line-height-display-sm font-semibold'}>
                {formal.subHeader}
            </Header>
            }
            {formal.paragraphs.map((p, pk) => {
                return <Text className='line-height-display-sm' key={pk}>
                    {p}
                </Text>
            })}
        </div>
    );
};

export default FormalSection;