import MinusCircle from "@untitled-ui/icons-react/build/cjs/MinusCircle";
import Text from "../../common/Text";
import Header from "../../common/Header";
const SingleContactInfo = ({className, header, paragraph, children}) => {
    return (
        <div className={`flex gap-x-2 ${className} flex-col md:flex-row`}>
            <div className="icon-backround">
                {children ? children : 
                    <MinusCircle size={'500'} className="text-white self-center"/>
                }
            </div>
            <div className="mt-1">
                <Header variant="h3" className='font-size-text-xl font-semibold line-height-text-xl'>{header}</Header>
                <Text className='font-size-text-md fine-height-text-md mt-1'>{paragraph}</Text>
            </div>
        </div>
    )
}
export default SingleContactInfo;