import { useTranslation } from "react-i18next";
import WrappedButton from "../../common/Button";
import Header from "../../common/Header";
import Text from "../../common/Text";
import "../../../App.scss"
import { Link } from "react-router-dom";
const HeroHeaderSection = () => {
    const {t,i18n} = useTranslation();

    return (
        <section className=" container flex flex-col-reverse md:flex-row">
            <section className="flex-1 pt-0 pb-7 md:pb-0 md:pt-4 self-center">
                <div className="breadcrumb-container flex min-w-fit w-min gap-2 pl-[12px] pr-[12px] pt-[6px] pb-[6px]">
                <img src="/img/header/dot.svg" className="h-[16px] w-[16px] self-center "/>
                    <div className="flex breadcrum-text-wrapper breadcrumb-text-border">
                        <Text variant="span" className='text-textSecondary-700 font-medium'>{t('heroHeader.breadcrumbText')}</Text>
                    </div>
                </div>
                <Header className="font-size-display-xl line-height-display-xl header whitespace-pre-line line-height-display-xl mt-[20px] md:mt-2">{t('heroHeader.header')}</Header>
                <Text className='font-size-text-xl mt-[20px] md:mt-3 font-normal line-height-text-x'>{t('heroHeader.paragraph')}</Text>
                <div className='mt-4 md:mt-5'>
                    <WrappedButton className='font-size-text-md link-button bg-primary-500 line-height-text-md'>
                        <Link to="/#contact-section">
                            {t('heroHeader.keepInTouchButton')}
                        </Link>
                    </WrappedButton>
                </div>
            </section>
            <section className="flex-1 self-center flex justify-end pt-[96px] md:pt-0 header-bcg">
                <img src={`/img/header/header-background-${i18n.language !=='de' ? "en" : "de"}.png`} className="self-end"/>
            </section>
        </section>
    )
};
export default HeroHeaderSection;