import FaqSection  from "../components/landing/faq/FaqSection"
import HeroHeaderSection from '../components/landing/heroHeader/HeroHeaderSection';
import CompanyCarouselSection from '../components/landing/companyCarousel/CompanyCarouselSection';
import ProblemSection from '../components/landing/problem/ProblemSection';
import ProductSection from '../components/landing/product/ProductSection';
import CertificationSection from '../components/landing/cerfitications/CertificationSection';
import TeamSection from '../components/landing/team/TeamSection';
import ContactFormSection from '../components/landing/contactForm/ContactFormSection';
import SolutionSection from "../components/landing/problem/SolutionSection";
const HomePage = () => {
    return (
        <section>
            <HeroHeaderSection/>
            <CompanyCarouselSection/>
            <ProblemSection />
            <SolutionSection/>
            <div className='container'>

                <ProductSection/>
                <CertificationSection />
                <TeamSection/>
                <FaqSection />
                <ContactFormSection />
            </div>
        </section>
    )
};

export default HomePage;