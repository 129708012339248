import Header from "../../common/Header"
import Text from "../../common/Text"
import AnnotationAlert from "@untitled-ui/icons-react/build/cjs/AnnotationAlert";
import "../../../App.scss"
const SingleSolution = ({header, paragraph, children}) => {
    return (
        <section className="flex-1 text-center bg-grey-400">
            <div className="flex justify-center">
                <div className="p-[12px] flex justify-center items-center solution-icon-wrapper w-[70px] h-[70px]">
                    {children ? children :<AnnotationAlert
                        size={"48"} 
                    />}
                </div>
            </div>
            <Header variant="h3" className="font-semibold font-size-text-xl mt-[16px] md:mt-[20px]">{header}</Header>
            <Text className="font-size-text-lg mt-[8px]">{paragraph}</Text>
        </section>
    )
}

export default SingleSolution;