import { useTranslation } from "react-i18next";
import SectionHeading from "../components/common/SectionHeading";
import FormalSection from "../components/privacyPolicy/FormalSection";


const ImpressumPage = () => {
    const {t} = useTranslation();

    return (
        <section className="container">
            <SectionHeading
                upperHeader={t("impressum.upperHeader")}
                header={t("impressum.header")}
                classesName={'pt-9 md:pb-9'}
                >
                    <section className="mt-[128px] md:mt-[192px]">

                        {t("impressum.paragraphs").map((item, index) => {
                            return (
                                <FormalSection formal={item} key={index}/>
                            )
                        })}
                    </section>
                </SectionHeading>

        </section>
    );
};

export default ImpressumPage;