import React from 'react';

const Header = ({ variant = 'h1', children, style, className }) => {
  if (variant === 'h1') {
    return (
      <h1 style={style} className={className}>
        {children}
      </h1>
    );
  }

  if (variant === 'h2') {
    return (
      <h2 style={style} className={className}>
        {children}
      </h2>
    );
  }

  if (variant === 'h3') {
    return (
      <h3 style={style} className={className}>
        {children}
      </h3>
    );
  }
  if (variant === 'h4') {
    return (
      <h4 style={style} className={className}>
        {children}
      </h4>
    );
  }
};

export default Header;
