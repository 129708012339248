import { useTranslation, Trans } from "react-i18next";
import SectionHeading from "../../common/SectionHeading";
import SingleQuestion from "./SingleQuestion";
import { Link } from "react-router-dom";

const FaqSection = () => {
    const {t} = useTranslation();
    const links = [
        {value: <Link to="">b</Link>, key:""},
        {value: <a href="mailto: support@docjo.health"></a>, key:""},
    ]
    return (
        <section className="pt-7 pb-7 md:pt-9 md:pb-9 border-b border-solid border-borderFaq w-full" 
            
        >
            <SectionHeading
                upperHeader={t('faq.upperHeader')}
                header={t('faq.header')}
                children={
                    <ul className="flex justify-center pt-7 flex-col">
                        {t('faq.questions').map((item, index) => {
                            return <SingleQuestion 
                                    question={item.question}
                                    answer={
                                        <Trans
                                        i18nKey={`faq.questions.${index}.answer`}
                                            components={{
                                            link1: <Link className="font-semibold-v2" to="/privacy-policy"></Link>,
                                            link2: <a className='font-semibold-v2' href="mailto: support@docjo.health"></a>
                                            }}
                    />
                                    }
                                    key={index}
                                    showBorder={index !== t('faq.questions').length-1}
                                    linking={links[index]}
                                />
                        })}
            </ul>
                }
            />
        </section>
    )
};
export default FaqSection;