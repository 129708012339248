import React from 'react';

const Text = ({ variant = 'p', children, style, className }) => {
  if (variant === 'p') {
    return (
      <p style={style} className={className}>
        {children}
      </p>
    );
  }

  if (variant === 'span') {
    return (
      <span style={style} className={className}>
        {children}
      </span>
    );
  }
};

export default Text;
