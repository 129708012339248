import { useState } from "react"
import Header from "../../common/Header";
import Text from "../../common/Text";
import PlusCircle from "@untitled-ui/icons-react/build/cjs/PlusCircle";
import MinusCircle from "@untitled-ui/icons-react/build/cjs/MinusCircle";

const SingleQuestion = ({question, answer, showBorder, linking}) => {
    const [isExpand, setIsExpand] = useState(false);
    const handleExpandClick = () => setIsExpand(!isExpand)
    return (
        <li className={`pt-3 pb-4 border-bottom-2 solid w-full ${showBorder !== true ? "" : "border-b border-solid border-borderFaq"}`}>
            <div className="flex justify-between">
                <Header variant="h3" className='text-left font-size-text-lg font-medium line-height-text-lg'>{question}</Header>
                {isExpand ?
                  <MinusCircle onClick={handleExpandClick} className="cursor-pointer"/>:
                  <PlusCircle onClick={handleExpandClick} className="cursor-pointer"/> }
            </div>
            <div 
                className={`overflow-hidden ease-in-out ${
                    isExpand 
                    ? 'max-h-96 opacity-100 duration-1000' 
                    : 'max-h-0 opacity-0 duration-300'
                }`}
            >
                <Text className='font-size-text-md line-height-text-md text-left mr-[32px] md:mr-[120px] mt-0 md:mt-2'>
                    {answer}
                </Text>
            </div>
        
        </li>
    )
}

export default SingleQuestion;